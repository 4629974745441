import React from "react";
import {HashRouter, Routes, Route} from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import ReferralRewards from "./pages/ReferralRewards";
import MyTeam from "./pages/MyTeam";
import {UserProvider} from "./context/UserContext";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
    return (
        <UserProvider>
            <HashRouter>
                <div>
                    <Header/>
                    <ScrollToTop />
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/referral_rewards" element={<ReferralRewards/>}/>
                        <Route path="/my_team" element={<MyTeam/>}/>
                    </Routes>
                </div>
            </HashRouter>
        </UserProvider>
    );
};

export default App;
