import axios from "axios";

const server = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  //baseURL: "http://127.0.0.1:9999/solana/",
  //baseURL: "http://52.23.217.94:9999/solana/",
  baseURL: "https://solape.info/solana/",
  timeout: 60000,
});

export default server;
