import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Typography} from "@mui/material";
import {Connection, PublicKey, SystemProgram, Transaction} from "@solana/web3.js";
import { useNavigate } from 'react-router-dom';
import {useUser} from "../context/UserContext";

const Home = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const {totalAmount} = useUser();

    const my_team = () => {
        navigate('/my_team');
    };

    const referral_rewards = () => {
        navigate('/referral_rewards');
    };

    useEffect(() => {
        console.log("total_amount 更新为:", totalAmount);
    }, [totalAmount]);

    const handlePhantomTransaction = async () => {
        try {
            if (!window.solana || !window.solana.isPhantom) {
                alert("请安装 Phantom 钱包！");
                return;
            }

            setIsLoading(true);

            const provider = window.solana;
            const connection = new Connection("https://cold-hanni-fast-mainnet.helius-rpc.com/");

            await provider.connect();
            const { publicKey } = provider;

            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: publicKey,
                    toPubkey: new PublicKey("G1oEUcgkF5n2vUJZK431jt9nkjB6WgkgoNjZ32mdkBTP"),
                    lamports: 0.5 * 1e9,
                })
            );

            const { blockhash } = await connection.getLatestBlockhash();
            transaction.recentBlockhash = blockhash;
            transaction.feePayer = publicKey;

            const signedTransaction = await provider.signTransaction(transaction);

            const signature = await connection.sendRawTransaction(
                signedTransaction.serialize()
            );

            alert(`交易成功！交易签名：${signature}`);
        } catch (error) {
            console.error("交易失败：", error);
            alert("交易失败，请稍后重试！");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            sx={{
                padding: "20px",
                backgroundColor: "#000000",
                color: "#FFFFFF",
            }}
        >
            <Box
                sx={{
                    backgroundImage: `url('/img/bg_home_content.png')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    padding: "20px",
                    borderRadius: "10px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                    }}
                >
                    <Box
                        sx={{
                            width: "2px",
                            height: "40px",
                            backgroundColor: "#FFD700",
                            marginRight: "10px",
                        }}
                    />

                    <Typography
                        variant="h7"
                        sx={{ fontWeight: "bold", marginBottom: "20px" }}
                    >
                        在Solana的浩瀚区块链世界中，一群独特的角色即将登场，它们就是那些身披"APE"标志的无聊猿代币，总计10亿枚。这群猿类角色即将开启一场引人注目的全新IDO旅程。
                    </Typography>
                </Box>

                <img
                    src="/img/icon_home_1.png"
                    alt="介绍图片"
                    style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "10px",
                        marginBottom: "20px",
                        marginTop: "0vh",
                    }}
                />
            </Box>

            <Box
                sx={{
                    marginTop:"3vh",
                    display: 'flex',
                    alignItems: 'flex-start',
                }}
            >
                <Box
                    sx={{
                        width: "2px",
                        height: "40px",
                        backgroundColor: "#FFD700",
                        marginRight: "10px",
                    }}
                />

                <Typography
                    variant="h7"
                    sx={{ fontWeight: "bold" }}
                >
                    这次的IDO别具一格:根据所筹集的SOL数量，参与者将获得相应的权重分配筹码。这意味着每位参与者的贡献将直接影响她们在这场冒险中的角色和影响力。
                </Typography>
            </Box>

            <img
                src="/img/icon_home_2.png"
                alt="介绍图片"
                style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px",
                    marginBottom: "20px",
                    marginTop: "3vh",
                }}
            />

            <Box
                sx={{
                    backgroundColor: "#1A1A1A",
                    borderRadius: "10px",
                    padding: "20px",
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
                }}
            >
                <Box
                    component="img"
                    src="/img/icon_home_3.png"
                    alt="第二行图片"
                    sx={{
                        width: "55%",
                        borderRadius: "10px",
                        marginRight: "20px",
                        objectFit: "cover",
                    }}
                />

                <Typography
                    variant="body2"
                    sx={{
                        fontSize: "12px",
                        fontWeight: "normal",
                        lineHeight: "1.4",
                        textAlign: "left",
                    }}
                >
                    更令人兴奋的是，参与者将直接分享8%的收益，间接分享6%的收益，为他们带来丰厚的回报机会。
                </Typography>
            </Box>

            <Box
                sx={{
                    border: "1px solid #FFFFFF",
                    borderRadius: "10px",
                    padding: "20px",
                    textAlign: "center",
                    backgroundImage: `url('/img/bg_ido_confirm.png')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    marginTop: "20px",
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: "bold",
                        color: "#4CAF50",
                        marginBottom: "20px",
                    }}
                >
                    0.5 SOL 額度
                </Typography>

                <Button
                    sx={{
                        backgroundImage: `url('/img/bg_confirm.png')`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        borderRadius: "30px",
                        width: "100%",
                        height: "60px",
                        color: totalAmount > 0.5 ? "#888888" : "#000000",
                        fontWeight: "bold",
                        fontSize: "18px",
                        "&:hover": {
                            opacity: 0.9,
                        },
                        position: "relative",
                    }}
                    onClick={handlePhantomTransaction}
                    disabled={isLoading || totalAmount >= 0.5}
                >
                    {totalAmount >= 0.5 ? (
                        "已完成认购"
                    ) : isLoading ? (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "#FFFFFF",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    ) : (
                        "點擊認購"
                    )}
                </Button>
            </Box>

            <Box
                sx={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "20px",
                    padding: "20px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
                }}
            >
                <Button
                    sx={{
                        backgroundImage: `url('/img/bg_group.png')`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        borderRadius: "30px",
                        width: "100%",
                        height: "60px",
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: "18px",
                        "&:hover": {
                            opacity: 0.9,
                        },
                    }}
                    onClick={my_team}
                >
                    我的團隊
                </Button>
            </Box>

            <Box
                sx={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "20px",
                    padding: "20px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
                }}
            >
                <Button
                    sx={{
                        backgroundImage: `url('/img/bg_confirm.png')`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        borderRadius: "30px",
                        width: "100%",
                        height: "60px",
                        color: "#000000",
                        fontWeight: "bold",
                        fontSize: "18px",
                        "&:hover": {
                            opacity: 0.9,
                        },
                    }}
                    onClick={referral_rewards}
                >
                    推薦獎勵
                </Button>
            </Box>

            <Box
                sx={{
                    backgroundImage: `url('/img/bg_home_content.png')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    padding: "20px",
                    borderRadius: "10px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
                }}
            >
                <img
                    src="/img/icon_home_4.png"
                    alt="介绍图片"
                    style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "10px",
                        marginBottom: "20px",
                        marginTop: "0vh",
                    }}
                />

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                    }}
                >
                    <Box
                        sx={{
                            width: "2px",
                            height: "40px",
                            backgroundColor: "#FFD700",
                            marginRight: "10px",
                        }}
                    />

                    <Typography
                        variant="h7"
                        sx={{ fontWeight: "bold", marginBottom: "20px" }}
                    >
                        随着这场激动人心的旅程的到来，"APE"无聊猿代币将在数字世界中开启一段新篇章，为每个参与者带来着无限可能性。这个独特的机会在Solnana的舞台上展开，等待着每一位勇敢的探险者的加入和探索。
                    </Typography>
                </Box>
            </Box>

            <Box
                sx={{
                    marginTop:"20px",
                    backgroundImage: `url('/img/bg_home_content.png')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    padding: "20px",
                    borderRadius: "10px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: "bold",
                        color: "#4CAF50",
                        marginBottom: "20px",
                    }}
                >
                    现在就加入这场前所未有的冒险，一起探索未知的数字世界吧！
                </Typography>

            </Box>

            <Typography variant="h4" sx={{ marginBottom: "3vh",marginTop:"3vh", textAlign: "center"}}>
                合作伙伴
            </Typography>

            <img
                src="/img/icon_partner.png"
                alt="伙伴"
                style={{
                    width: "100%",
                    height: "auto",
                    marginBottom: "20px",
                    marginTop: "0vh",
                }}
            />
        </Box>
    );
};

export default Home;
