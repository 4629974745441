import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useUser } from "../context/UserContext";
import server from "../utils/require";

const ReferralRewards = () => {
    const [integral, setIntegral] = useState(0);
    const [total, setTotal] = useState(0);
    const { userId, inviteCode } = useUser();

    useEffect(() => {
        if (userId) {
            fetchTeamInfo(userId).then();
        }
    }, [userId]);

    const fetchTeamInfo = async (userId) => {
        try {
            const payload = {
                user_id: userId,
            };

            server.request({
                url: "/user/get_user_points",
                method: "post",
                data: payload
            })
                .then((response) => {
                    const data = response.data;
                    setIntegral(data.points);
                    setTotal(data.total);
                })
                .catch((error) => {
                    console.error("请求失败:", error);
                });
        } catch (error) {
            console.error("请求失败:", error);
        }
    };

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(`https://solape.info?code=${inviteCode}`);
            alert("推广链接已复制!");
        } catch (error) {
            alert("复制失败，请手动复制");
            console.error("复制失败:", error);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
                background: "#000000",
                color: "#FFFFFF",
                height: "100vh",
            }}
        >
            <Typography variant="h4" sx={{ textAlign: "center", marginBottom: "20px", marginTop: "20px" }}>
                推荐奖励
            </Typography>
            <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
                <Typography variant="h6" sx={{ marginBottom: "10px", fontSize: "40px" }}>
                    团队总入金: {total}
                </Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
                <Typography variant="h6" sx={{ marginBottom: "10px", fontSize: "40px" }}>
                    二代推荐奖励: {integral}
                </Typography>
            </Box>
        </Box>
    );
};

export default ReferralRewards;
