import React, {useState, useEffect} from "react";
import {AppBar, Toolbar, Box, Typography, Button} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import server from "../utils/require";
import {useUser} from "../context/UserContext";
import {useNavigate} from "react-router-dom";

const Header = () => {
    const [walletAddress, setWalletAddress] = useState(null);
    const [loading, setLoading] = useState(false);
    const { login } = useUser();
    const navigate = useNavigate();

    const home = () => {
        navigate('/');
    };

    useEffect(() => {
        const autoLogin = async () => {
            if (window.solana && window.solana.isPhantom) {
                try {
                    const { publicKey } = await window.solana.connect({ onlyIfTrusted: true });
                    const address = publicKey.toString();
                    setWalletAddress(address);

                    const searchParams = new URLSearchParams(window.location.search);
                    const inviteCode = searchParams.get("code");
                    console.log("inviteCode", inviteCode);

                    sendLoginRequest(address, inviteCode).then();
                } catch (err) {
                    console.error("自动登录失败:", err);
                }
            }
        };

        autoLogin().then();
    }, []);

    const connectWallet = async () => {
        if (window.solana) {
            try {
                const response = await window.solana.connect();
                const address = response.publicKey.toString();
                setWalletAddress(address);

                const searchParams = new URLSearchParams(window.location.search);
                const inviteCode = searchParams.get("code");
                console.log("inviteCode", inviteCode);
                sendLoginRequest(address, inviteCode).then();
            } catch (error) {
                console.error("钱包连接失败:", error);
            }
        } else {
            alert("请安装 Phantom 钱包插件！");
        }
    };

    const sendLoginRequest = async (address, inviteCode) => {
        if (loading) return;
        setLoading(true);
        try {
            const payload = {
                wallet_address: address,
                referral_code: inviteCode || "",
            };

            server.request({
                url: "/user/register_and_login",
                method: "post",
                data: payload
            })
                .then((response) => {
                    console.log("登录成功:", response.data);
                    const data = response.data;
                    login(data.id,data.referral_code,data.total_amount);
                })
                .catch((error) => {
                    console.error("登录请求失败:", error);
                });
        } catch (error) {
            console.error("登录请求失败:", error);
        } finally {
            setLoading(false);
        }
    };

    const renderWalletInfo = () => {
        if (walletAddress) {
            return (
                <Typography variant="body1" sx={{color: "#FFFFFF"}}>
                    {`${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)}`}
                </Typography>
            );
        } else {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={connectWallet}
                    sx={{
                        backgroundColor: "#FFD700",
                        color: "#111",
                        fontWeight: "bold",
                        "&:hover": {backgroundColor: "#FFC107"},
                    }}
                >
                    连接钱包
                </Button>
            );
        }
    };

    return (
        <AppBar
            position="static"
            color="transparent"
            elevation={1}
            sx={{
                background: `url('/img/bg_header.png') no-repeat center center`,
                backgroundSize: 'cover',
            }}
        >
            <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 20px" }}>
                <img
                    src="/img/logo.jpg"
                    alt="伙伴"
                    style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        marginRight: "30px",
                    }}
                    onClick={home}
                />
                <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    {renderWalletInfo()}
                    <Button variant="text" sx={{ color: "#FFFFFF" }}>
                        <LanguageIcon sx={{ color: "black" }} />
                    </Button>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
