import React, { createContext, useContext, useState } from "react";

const UserContext = createContext();

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [userId, setUserId] = useState(null);
    const [inviteCode, setInviteCode] = useState(null);
    const [totalAmount, setTotalAmount] = useState(null);


    const login = (id,code,amount) => {
        setUserId(id);
        setInviteCode(code);
        setTotalAmount(amount)
    };

    return (
        <UserContext.Provider value={{ userId, inviteCode,totalAmount, login }}>
            {children}
        </UserContext.Provider>
    );
};
