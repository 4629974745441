import React, {useEffect, useState} from "react";
import {Box, Typography, Button, Snackbar} from "@mui/material";
import {useUser} from "../context/UserContext";
import server from "../utils/require";

const MyTeam = () => {
    const [directWallets, setDirectWallets] = useState([]);
    const [indirectWallets, setIndirectWallets] = useState([]);
    const [showDirect, setShowDirect] = useState(true);
    const [showIndirect, setShowIndirect] = useState(false);
    const [userLink, setUserLink] = useState("https://solape.info");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const {userId, inviteCode, amount} = useUser();

    useEffect(() => {
        if (userId) {
            fetchWalletLists(userId).then();
        }
    }, [userId]);

    useEffect(() => {
        if (inviteCode) {
            setUserLink(`https://solape.info?code=${inviteCode}`);
        }
    }, [inviteCode]);

    const fetchWalletLists = async (userId) => {
        try {
            const payload = {
                user_id: userId,
            };

            server
                .request({
                    url: "/user/get_invite_user_list",
                    method: "post",
                    data: payload,
                })
                .then((response) => {
                    const data = response.data;
                    console.log("邀请列表获取成功:", data);
                    setDirectWallets(data.direct_invites_list || []);
                    setIndirectWallets(data.indirect_invites_list || []);
                })
                .catch((error) => {
                    console.error("邀请列表请求失败:", error);
                });
        } catch (error) {
            console.error("邀请列表请求失败:", error);
        }
    };

    const handleCopy = async () => {
        const text = `https://solape.info?code=${inviteCode}`;

        try {
            await navigator.clipboard.writeText(text);
            setSnackbarOpen(true); // 显示提示条
        } catch (error) {
            console.error("复制推广链接失败:", error);
            alert("复制失败，请手动复制！");
        }
    };

    const formatWallet = (wallet) => {
        if (wallet.length > 24) {
            return `${wallet.slice(0, 12)}...${wallet.slice(-12)}`;
        }
        return wallet;
    };

    return (
        <Box
            sx={{
                padding: "20px",
                backgroundColor: "#000000",
                color: "#FFFFFF",
                height: "100vh",
                overflowY: "auto",
            }}
        >
            <Typography
                variant="h4"
                sx={{textAlign: "center", marginBottom: "20px", marginTop: "20px"}}
            >
                我的团队
            </Typography>

            {/* 推广链接复制按钮 */}
            <Box sx={{textAlign: "center", marginBottom: "20px"}}>
                <Typography variant="h6" sx={{marginBottom: "10px"}}>
                    推广链接: {userLink}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCopy}
                    sx={{fontSize: "16px"}}
                >
                    复制推广链接
                </Button>
            </Box>

            {/* 按钮区域 */}
            <Box sx={{textAlign: "center", marginBottom: "20px"}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setShowDirect(true);
                        setShowIndirect(false);
                    }}
                    sx={{marginRight: "10px", fontSize: "16px"}}
                >
                    查看直推钱包地址
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        setShowDirect(false);
                        setShowIndirect(true);
                    }}
                    sx={{fontSize: "16px"}}
                >
                    查看间推钱包地址
                </Button>
            </Box>

            {/* 列表展示区域 */}
            <Box
                sx={{
                    marginTop: "20px",
                    textAlign: "center",
                    padding: "0 20px",
                }}
            >
                {showDirect && directWallets.length > 0 && (
                    <Box>
                        <Typography
                            variant="h6"
                            sx={{marginBottom: "10px", textAlign: "center"}}
                        >
                            直推钱包地址
                        </Typography>
                        <Box>
                            {directWallets.map((wallet, index) => (
                                <Typography
                                    key={index}
                                    variant="body1"
                                    sx={{marginBottom: "5px", textAlign: "center"}}
                                >
                                    {formatWallet(wallet)}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                )}
                {showIndirect && indirectWallets.length > 0 && (
                    <Box>
                        <Typography
                            variant="h6"
                            sx={{marginBottom: "10px", textAlign: "center"}}
                        >
                            间推钱包地址
                        </Typography>
                        <Box>
                            {indirectWallets.map((wallet, index) => (
                                <Typography
                                    key={index}
                                    variant="body1"
                                    sx={{marginBottom: "5px", textAlign: "center"}}
                                >
                                    {formatWallet(wallet)}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                )}
                {showDirect && directWallets.length === 0 && (
                    <Typography variant="h6" sx={{marginTop: "20px", textAlign: "center"}}>
                        当前没有直推钱包地址
                    </Typography>
                )}
                {showIndirect && indirectWallets.length === 0 && (
                    <Typography variant="h6" sx={{marginTop: "20px", textAlign: "center"}}>
                        当前没有间推钱包地址
                    </Typography>
                )}
            </Box>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={() => setSnackbarOpen(false)}
                message="推广链接已复制！"
                sx={{bottom: 100}}
            />
        </Box>
    );
};

export default MyTeam;
